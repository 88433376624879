exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-guide-to-hiring-a-team-js": () => import("./../../../src/pages/guide-to-hiring-a-team.js" /* webpackChunkName: "component---src-pages-guide-to-hiring-a-team-js" */),
  "component---src-pages-hire-android-app-developers-js": () => import("./../../../src/pages/hire-android-app-developers.js" /* webpackChunkName: "component---src-pages-hire-android-app-developers-js" */),
  "component---src-pages-hire-angular-developers-js": () => import("./../../../src/pages/hire-angular-developers.js" /* webpackChunkName: "component---src-pages-hire-angular-developers-js" */),
  "component---src-pages-hire-backend-developers-js": () => import("./../../../src/pages/hire-backend-developers.js" /* webpackChunkName: "component---src-pages-hire-backend-developers-js" */),
  "component---src-pages-hire-database-developers-js": () => import("./../../../src/pages/hire-database-developers.js" /* webpackChunkName: "component---src-pages-hire-database-developers-js" */),
  "component---src-pages-hire-dedicated-developers-js": () => import("./../../../src/pages/hire-dedicated-developers.js" /* webpackChunkName: "component---src-pages-hire-dedicated-developers-js" */),
  "component---src-pages-hire-django-developers-js": () => import("./../../../src/pages/hire-django-developers.js" /* webpackChunkName: "component---src-pages-hire-django-developers-js" */),
  "component---src-pages-hire-dot-net-developers-js": () => import("./../../../src/pages/hire-dot-net-developers.js" /* webpackChunkName: "component---src-pages-hire-dot-net-developers-js" */),
  "component---src-pages-hire-express-js-developers-js": () => import("./../../../src/pages/hire-express-js-developers.js" /* webpackChunkName: "component---src-pages-hire-express-js-developers-js" */),
  "component---src-pages-hire-flutter-developers-js": () => import("./../../../src/pages/hire-flutter-developers.js" /* webpackChunkName: "component---src-pages-hire-flutter-developers-js" */),
  "component---src-pages-hire-frontend-developers-js": () => import("./../../../src/pages/hire-frontend-developers.js" /* webpackChunkName: "component---src-pages-hire-frontend-developers-js" */),
  "component---src-pages-hire-fullstack-developer-js": () => import("./../../../src/pages/hire-fullstack-developer.js" /* webpackChunkName: "component---src-pages-hire-fullstack-developer-js" */),
  "component---src-pages-hire-html-css-developers-js": () => import("./../../../src/pages/hire-html-css-developers.js" /* webpackChunkName: "component---src-pages-hire-html-css-developers-js" */),
  "component---src-pages-hire-ios-app-developers-js": () => import("./../../../src/pages/hire-ios-app-developers.js" /* webpackChunkName: "component---src-pages-hire-ios-app-developers-js" */),
  "component---src-pages-hire-java-developers-js": () => import("./../../../src/pages/hire-java-developers.js" /* webpackChunkName: "component---src-pages-hire-java-developers-js" */),
  "component---src-pages-hire-javascript-developers-js": () => import("./../../../src/pages/hire-javascript-developers.js" /* webpackChunkName: "component---src-pages-hire-javascript-developers-js" */),
  "component---src-pages-hire-laravel-developers-js": () => import("./../../../src/pages/hire-laravel-developers.js" /* webpackChunkName: "component---src-pages-hire-laravel-developers-js" */),
  "component---src-pages-hire-mean-stack-developers-js": () => import("./../../../src/pages/hire-mean-stack-developers.js" /* webpackChunkName: "component---src-pages-hire-mean-stack-developers-js" */),
  "component---src-pages-hire-mern-stack-developers-js": () => import("./../../../src/pages/hire-mern-stack-developers.js" /* webpackChunkName: "component---src-pages-hire-mern-stack-developers-js" */),
  "component---src-pages-hire-mobile-app-developers-js": () => import("./../../../src/pages/hire-mobile-app-developers.js" /* webpackChunkName: "component---src-pages-hire-mobile-app-developers-js" */),
  "component---src-pages-hire-next-js-developers-js": () => import("./../../../src/pages/hire-next-js-developers.js" /* webpackChunkName: "component---src-pages-hire-next-js-developers-js" */),
  "component---src-pages-hire-nodejs-developers-js": () => import("./../../../src/pages/hire-nodejs-developers.js" /* webpackChunkName: "component---src-pages-hire-nodejs-developers-js" */),
  "component---src-pages-hire-php-developers-js": () => import("./../../../src/pages/hire-php-developers.js" /* webpackChunkName: "component---src-pages-hire-php-developers-js" */),
  "component---src-pages-hire-python-developers-js": () => import("./../../../src/pages/hire-python-developers.js" /* webpackChunkName: "component---src-pages-hire-python-developers-js" */),
  "component---src-pages-hire-react-js-developers-js": () => import("./../../../src/pages/hire-react-js-developers.js" /* webpackChunkName: "component---src-pages-hire-react-js-developers-js" */),
  "component---src-pages-hire-react-native-developers-js": () => import("./../../../src/pages/hire-react-native-developers.js" /* webpackChunkName: "component---src-pages-hire-react-native-developers-js" */),
  "component---src-pages-hire-symfony-developers-js": () => import("./../../../src/pages/hire-symfony-developers.js" /* webpackChunkName: "component---src-pages-hire-symfony-developers-js" */),
  "component---src-pages-hire-vue-js-developers-js": () => import("./../../../src/pages/hire-vue-js-developers.js" /* webpackChunkName: "component---src-pages-hire-vue-js-developers-js" */),
  "component---src-pages-hire-web-developers-js": () => import("./../../../src/pages/hire-web-developers.js" /* webpackChunkName: "component---src-pages-hire-web-developers-js" */),
  "component---src-pages-hire-xamarin-developers-js": () => import("./../../../src/pages/hire-xamarin-developers.js" /* webpackChunkName: "component---src-pages-hire-xamarin-developers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-clients-js": () => import("./../../../src/pages/our-clients.js" /* webpackChunkName: "component---src-pages-our-clients-js" */),
  "component---src-pages-request-quote-js": () => import("./../../../src/pages/request-quote.js" /* webpackChunkName: "component---src-pages-request-quote-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-why-outsource-js": () => import("./../../../src/pages/why-outsource.js" /* webpackChunkName: "component---src-pages-why-outsource-js" */),
  "component---src-pages-why-outsource-to-india-js": () => import("./../../../src/pages/why-outsource-to-india.js" /* webpackChunkName: "component---src-pages-why-outsource-to-india-js" */),
  "component---src-pages-why-outsource-to-thinkodc-js": () => import("./../../../src/pages/why-outsource-to-thinkodc.js" /* webpackChunkName: "component---src-pages-why-outsource-to-thinkodc-js" */),
  "component---src-pages-works-js": () => import("./../../../src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-advantages-of-using-laravel-for-php-web-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/advantages-of-using-laravel-for-php-web-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-advantages-of-using-laravel-for-php-web-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-all-about-express-js-framework-for-web-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/all-about-express-js-framework-for-web-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-all-about-express-js-framework-for-web-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-api-encryption-and-decryption-with-python-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/api-encryption-and-decryption-with-python/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-api-encryption-and-decryption-with-python-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-and-challenges-of-hiring-ios-app-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-and-challenges-of-hiring-ios-app-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-and-challenges-of-hiring-ios-app-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-it-staff-augmentation-for-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-it-staff-augmentation-for-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-it-staff-augmentation-for-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-outsourcing-mobile-app-development-in-india-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-outsourcing-mobile-app-development-in-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-outsourcing-mobile-app-development-in-india-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-using-react-for-web-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/benefits-of-using-react-for-web-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-benefits-of-using-react-for-web-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-best-java-developers-for-your-project-success-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/best-java-developers-for-your-project-success/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-best-java-developers-for-your-project-success-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-best-practices-for-building-secure-and-scalable-software-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/best-practices-for-building-secure-and-scalable-software/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-best-practices-for-building-secure-and-scalable-software-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-best-react-native-developer-tools-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/best-react-native-developer-tools/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-best-react-native-developer-tools-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-dynamic-and-responsive-web-app-with-angularjs-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-dynamic-and-responsive-web-app-with-angularjs/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-dynamic-and-responsive-web-app-with-angularjs-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-infrastructure-with-cdk-for-iac-in-java-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-infrastructure-with-cdk-for-iac-in-java/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-infrastructure-with-cdk-for-iac-in-java-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-machine-learning-team-with-python-experts-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-machine-learning-team-with-python-experts/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-machine-learning-team-with-python-experts-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-build-scalable-microservices-architecture-with-python-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/build-scalable-microservices-architecture-with-python/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-build-scalable-microservices-architecture-with-python-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-when-outsourcing-work-to-indian-agency-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/challenges-when-outsourcing-work-to-indian-agency/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-challenges-when-outsourcing-work-to-indian-agency-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choose-right-ecommerce-platform-for-your-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/choose-right-ecommerce-platform-for-your-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choose-right-ecommerce-platform-for-your-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-for-java-spring-boot-microservices-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/complete-guide-for-java-spring-boot-microservices/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-for-java-spring-boot-microservices-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-hire-dedicated-programmers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/complete-guide-to-hire-dedicated-programmers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-complete-guide-to-hire-dedicated-programmers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-comprehensive-guide-to-dot-net-framework-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/comprehensive-guide-to-dot-net-framework/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-comprehensive-guide-to-dot-net-framework-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-comprehensive-guide-to-full-stack-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/comprehensive-guide-to-full-stack-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-comprehensive-guide-to-full-stack-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-benefit-analysis-of-outsourcing-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-benefit-analysis-of-outsourcing/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-benefit-analysis-of-outsourcing-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-hiring-dedicated-android-app-developer-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-of-hiring-dedicated-android-app-developer/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-hiring-dedicated-android-app-developer-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-hiring-dedicated-ios-app-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-of-hiring-dedicated-ios-app-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-hiring-dedicated-ios-app-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-hiring-full-stack-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-of-hiring-full-stack-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-hiring-full-stack-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-hiring-react-native-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-of-hiring-react-native-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-hiring-react-native-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-react-native-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-of-react-native-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-of-react-native-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-to-hire-dedicated-angular-developer-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-to-hire-dedicated-angular-developer/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-to-hire-dedicated-angular-developer-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-cost-to-hire-reactjs-developer-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/cost-to-hire-reactjs-developer/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-cost-to-hire-reactjs-developer-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-creating-scalable-and-secure-dot-net-app-best-practices-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/creating-scalable-and-secure-dot-net-app-best-practices/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-creating-scalable-and-secure-dot-net-app-best-practices-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-developing-fitness-tracking-app-with-flutter-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/developing-fitness-tracking-app-with-flutter/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-developing-fitness-tracking-app-with-flutter-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-flutter-for-healthcare-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/flutter-for-healthcare-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-flutter-for-healthcare-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-flutter-future-cross-platform-app-development-2024-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/flutter-future-cross-platform-app-development-2024/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-flutter-future-cross-platform-app-development-2024-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-for-python-web-scraping-libraries-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-for-python-web-scraping-libraries/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-for-python-web-scraping-libraries-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-hire-java-developer-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-hire-java-developer/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-hire-java-developer-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-hire-right-dedicated-developer-for-your-team-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-hire-right-dedicated-developer-for-your-team/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-hire-right-dedicated-developer-for-your-team-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-python-frameworks-for-scalable-microservices-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-python-frameworks-for-scalable-microservices/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-python-frameworks-for-scalable-microservices-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-react-native-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-react-native-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-react-native-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-xamarin-vs-flutter-cross-platform-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-xamarin-vs-flutter-cross-platform/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-xamarin-vs-flutter-cross-platform-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-hire-dedicated-backend-developer-for-your-next-project-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/hire-dedicated-backend-developer-for-your-next-project/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-hire-dedicated-backend-developer-for-your-next-project-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-hire-developers-for-startups-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/hire-developers-for-startups/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-hire-developers-for-startups-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-hire-flutter-developers-for-on-demand-delivery-apps-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/hire-flutter-developers-for-on-demand-delivery-apps/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-hire-flutter-developers-for-on-demand-delivery-apps-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-hire-nodejs-developers-for-your-startup-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/hire-nodejs-developers-for-your-startup/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-hire-nodejs-developers-for-your-startup-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-full-stack-developers-integrate-frontend-and-backend-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-full-stack-developers-integrate-frontend-and-backend-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-full-stack-developers-integrate-frontend-and-backend-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-reactjs-helps-in-building-ui-ux-for-your-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-reactjs-helps-in-building-ui-ux-for-your-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-reactjs-helps-in-building-ui-ux-for-your-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-choose-the-best-python-web-development-company-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-choose-the-best-python-web-development-company/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-choose-the-best-python-web-development-company-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-hire-flutter-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-hire-flutter-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-hire-flutter-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-hire-nodejs-developer-todc-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-hire-nodejs-developer-todc/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-hire-nodejs-developer-todc-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-hire-react-js-developer-for-fintech-industry-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-hire-react.js-developer-for-fintech-industry/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-hire-react-js-developer-for-fintech-industry-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-hire-software-developers-for-your-project-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-hire-software-developers-for-your-project/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-hire-software-developers-for-your-project-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-outsource-laravel-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-to-outsource-laravel-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-to-outsource-laravel-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-in-house-vs-it-outsourcing-for-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/in-house-vs-it-outsourcing-for-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-in-house-vs-it-outsourcing-for-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-laravel-the-perfect-php-framework-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/laravel-the-perfect-php-framework/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-laravel-the-perfect-php-framework-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-latest-guide-to-hire-dedicated-php-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/latest-guide-to-hire-dedicated-php-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-latest-guide-to-hire-dedicated-php-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-launching-your-business-with-offshore-development-team-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/launching-your-business-with-offshore-development-team/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-launching-your-business-with-offshore-development-team-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-maximize-roi-with-it-staff-augmentation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/maximize-roi-with-it-staff-augmentation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-maximize-roi-with-it-staff-augmentation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-mistakes-to-avoid-when-hiring-web-development-company-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/mistakes-to-avoid-when-hiring-web-development-company/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-mistakes-to-avoid-when-hiring-web-development-company-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-must-have-features-of-an-android-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/must-have-features-of-an-android-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-must-have-features-of-an-android-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-next-big-thing-node-js-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/next-big-thing-node-js-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-next-big-thing-node-js-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-reactjs-key-differences-and-use-cases-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/nodejs-vs-reactjs-key-differences-and-use-cases/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-nodejs-vs-reactjs-key-differences-and-use-cases-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-offshore-staff-augmentation-solution-for-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/offshore-staff-augmentation-solution-for-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-offshore-staff-augmentation-solution-for-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-offshore-vs-nearshore-outsourcing-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/offshore-vs-nearshore-outsourcing/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-offshore-vs-nearshore-outsourcing-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-outsourcing-vs-it-staff-augmentation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/outsourcing-vs-it-staff-augmentation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-outsourcing-vs-it-staff-augmentation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-outstaffing-vs-outsourcing-choosing-for-your-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/outstaffing-vs-outsourcing-choosing-for-your-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-outstaffing-vs-outsourcing-choosing-for-your-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-power-of-javascript-unlocking-endless-possibilities-in-web-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/power-of-javascript-unlocking-endless-possibilities-in-web-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-power-of-javascript-unlocking-endless-possibilities-in-web-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-proven-remote-developer-retention-strategies-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/proven-remote-developer-retention-strategies/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-proven-remote-developer-retention-strategies-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-python-for-cybersecurity-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/python-for-cybersecurity/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-python-for-cybersecurity-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-react-native-for-startups-excellent-choice-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/react-native-for-startups-excellent-choice/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-react-native-for-startups-excellent-choice-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-real-cost-of-hiring-developers-india-vs-other-countries-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/real-cost-of-hiring-developers-india-vs-other-countries/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-real-cost-of-hiring-developers-india-vs-other-countries-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-to-choose-react-native-for-mobile-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/reasons-to-choose-react-native-for-mobile-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-to-choose-react-native-for-mobile-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-to-hire-dedicated-developers-for-custom-software-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/reasons-to-hire-dedicated-developers-for-custom-software-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-to-hire-dedicated-developers-for-custom-software-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-to-use-vue-js-for-frontend-project-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/reasons-to-use-vue-js-for-frontend-project/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-reasons-to-use-vue-js-for-frontend-project-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-role-it-staff-augmentation-in-digital-transformation-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/role-it-staff-augmentation-in-digital-transformation/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-role-it-staff-augmentation-in-digital-transformation-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-roles-responsibilities-and-skills-of-php-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/roles-responsibilities-and-skills-of-php-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-roles-responsibilities-and-skills-of-php-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-save-time-with-python-automation-in-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/save-time-with-python-automation-in-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-save-time-with-python-automation-in-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-scale-up-faster-with-dedicated-developers-from-thinkodc-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/scale-up-faster-with-dedicated-developers-from-thinkodc/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-scale-up-faster-with-dedicated-developers-from-thinkodc-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-secrets-for-successful-python-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/secrets-for-successful-python-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-secrets-for-successful-python-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-skill-of-android-app-developer-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/skill-of-android-app-developer/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-skill-of-android-app-developer-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-skill-to-look-when-hiring-frontend-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/skill-to-look-when-hiring-frontend-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-skill-to-look-when-hiring-frontend-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-and-tricks-for-hiring-ios-app-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/tips-and-tricks-for-hiring-ios-app-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-and-tricks-for-hiring-ios-app-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-for-remote-team-management-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/tips-for-remote-team-management/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-for-remote-team-management-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-find-mobile-application-developer-for-startup-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/tips-to-find-mobile-application-developer-for-startup/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-find-mobile-application-developer-for-startup-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-improve-and-optimize-mobile-app-performance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/tips-to-improve-and-optimize-mobile-app-performance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-improve-and-optimize-mobile-app-performance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-optimize-react-application-performance-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/tips-to-optimize-react-application-performance/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-tips-to-optimize-react-application-performance-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-challenges-and-solutions-for-hiring-developers-in-india-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-challenges-and-solutions-for-hiring-developers-in-india/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-challenges-and-solutions-for-hiring-developers-in-india-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-offshore-software-development-trends-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-offshore-software-development-trends/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-offshore-software-development-trends-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-top-php-development-frameworks-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/top-php-development-frameworks/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-top-php-development-frameworks-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-transform-serverless-app-development-with-java-lambda-micronaut-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/transform-serverless-app-development-with-java-lambda-micronaut/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-transform-serverless-app-development-with-java-lambda-micronaut-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-ultimate-guide-to-angular-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/ultimate-guide-to-angular/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-ultimate-guide-to-angular-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-unlocking-power-node-js-top-5-best-use-cases-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/unlocking-power-node-js-top-5-best-use-cases/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-unlocking-power-node-js-top-5-best-use-cases-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-web-development-driving-digital-transformation-for-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/web-development-driving-digital-transformation-for-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-web-development-driving-digital-transformation-for-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-which-one-to-choose-fixed-price-vs-time-and-material-vs-dedicated-team-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/which-one-to-choose-fixed-price-vs-time-and-material-vs-dedicated-team/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-which-one-to-choose-fixed-price-vs-time-and-material-vs-dedicated-team-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-flutter-for-ar-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-flutter-for-ar-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-flutter-for-ar-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-flutter-for-mobile-app-mvp-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-flutter-for-mobile-app-mvp/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-flutter-for-mobile-app-mvp-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-india-for-outsourcing-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-india-for-outsourcing/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-india-for-outsourcing-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-mern-stack-for-web-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-mern-stack-for-web-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-mern-stack-for-web-app-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-python-for-your-next-project-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-python-for-your-next-project/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-python-for-your-next-project-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-react-native-for-mvp-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-react-native-for-mvp-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-react-native-for-mvp-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-reactjs-developers-for-build-an-app-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-reactjs-developers-for-build-an-app/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-reactjs-developers-for-build-an-app-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-reactjs-for-front-end-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-reactjs-for-front-end-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-reactjs-for-front-end-development-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-thinkodc-as-your-python-development-partner-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-thinkodc-as-your-python-development-partner/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-thinkodc-as-your-python-development-partner-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-todc-as-your-development-partner-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-choose-todc-as-your-development-partner/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-choose-todc-as-your-development-partner-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-hire-dedicated-development-team-instead-of-freelancers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-hire-dedicated-development-team-instead-of-freelancers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-hire-dedicated-development-team-instead-of-freelancers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-hire-dedicated-html-css-developers-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-hire-dedicated-html-css-developers/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-hire-dedicated-html-css-developers-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-hire-react-native-developers-for-mobile-apps-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-hire-react-native-developers-for-mobile-apps/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-hire-react-native-developers-for-mobile-apps-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-hire-web-developers-for-robust-web-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-hire-web-developers-for-robust-web-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-hire-web-developers-for-robust-web-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-hybrid-mobile-apps-are-good-idea-for-business-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-hybrid-mobile-apps-are-good-idea-for-business/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-hybrid-mobile-apps-are-good-idea-for-business-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-why-use-nodejs-for-real-time-app-development-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/why-use-nodejs-for-real-time-app-development/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-why-use-nodejs-for-real-time-app-development-index-mdx" */),
  "component---src-templates-portfolio-categories-js": () => import("./../../../src/templates/portfolio-categories.js" /* webpackChunkName: "component---src-templates-portfolio-categories-js" */),
  "component---src-templates-portfolio-single-js": () => import("./../../../src/templates/portfolio-single.js" /* webpackChunkName: "component---src-templates-portfolio-single-js" */)
}

